import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Profile from "../Profile";

function TopNav() {
  const { user } = useContext(AuthContext);

  return (
    <div className="bg-base-100 border-b border-base-300 min-h-16 flex items-center justify-between px-5">
      <div className="flex items-center ml-auto">
        <Profile />
      </div>
    </div>
  );
}

export default TopNav;

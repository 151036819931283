import axios from "axios";

const backendUrl =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_DEV; // Use environment variable or default to localhost

export const createStripeCustomer = async (userId, email) => {
  try {
    const response = await axios.post(`${backendUrl}/create-stripe-customer`, {
      // Use the full backend URL
      userId,
      email,
    });
    console.log("Stripe customer response:", response.data); // Log the response
    return response.data;
  } catch (error) {
    console.error("Error creating Stripe customer:", error);
    throw error;
  }
};

export const createCheckoutSession = async (
  stripeCustomerId,
  successUrl,
  cancelUrl
) => {
  try {
    const response = await axios.post(`${backendUrl}/create-checkout-session`, {
      stripeCustomerId,
      successUrl,
      cancelUrl,
    });
    console.log("Checkout session response:", response.data); // Log the response
    return response.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

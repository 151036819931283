// src/layouts/DashboardLayout.js
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import SideNav from "../components/navigation/SideNav";
import TopNav from "../components/navigation/TopNav";
import Loader from "../components/Loader";


const DashboardLayout = () => {
  return (
    <div className="dashboard-layout flex">
      <SideNav />
      <div className="content flex-1">
        <TopNav />
        <main className="p-6">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;

import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";

const PublicRoute = ({ children }) => {
  const { user, loading } = React.useContext(AuthContext);

  if (loading) {
    return <div><Loader /></div>; // Show a loading spinner or placeholder
  }

  // Redirect to dashboard if user is logged in
  return user ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;

import React, { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";

//Update the StoreStatus component to fetch and save the store status to Firestore
function StoreStatus() {
  const [storeStatus, setStoreStatus] = useState(false);
  const storeStatusToggle = async () => {
    const newStatus = !storeStatus;
    setStoreStatus(newStatus);

    try {
      const user = auth.currentUser;
      if (user) {
        await setDoc(doc(db, 'users', user.uid), {
          storePublished: newStatus
        }, { merge: true });
      }
    } catch (error) {
      console.error('Error saving store status:', error);
    }
  };

  // Fetch store status from Firestore on load (component mount)
  useEffect(() => {
    const fetchStoreStatus = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setStoreStatus(userDoc.data().storePublished);
          }
        }
      } catch (error) {
        console.error('Error fetching store status:', error);
      }
    };

    fetchStoreStatus();
  }, []);

  return (
    <div className="flex justify-between items-center w-full rounded-md py-3 px-2">
      {storeStatus ? <h1>Store Online</h1> : <h1>Store Offline</h1>}
      <button
        className={`relative inline-flex items-center h-6 w-12 rounded-full transition-colors ${
          storeStatus ? "bg-green" : "bg-gray"
        }`}
        onClick={storeStatusToggle}
      >
        <span
          className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform ${
            storeStatus ? "translate-x-6" : "translate-x-0"
          }`}
        ></span>
      </button>
    </div>
  );
}

export default StoreStatus;

import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-container max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions</h1>
      <p className="text-sm text-gray-600 mb-4 text-center">
        Last updated: January 1, 2025
      </p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-gray-700">
          Welcome to QRdrly. These Terms and Conditions ("Terms") govern your
          use of our online ordering platform and services, including payments
          and delivery features, designed specifically for food businesses
          ("Services"). By signing up for our Services, you ("Business" or
          "You") agree to comply with and be bound by these Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">2. Eligibility</h2>
        <p className="text-gray-700">
          By signing up for QRdrly, you confirm that you are a registered food
          business and have the authority to enter into this agreement. You
          must be at least 18 years old and legally capable of entering into
          contracts.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">3. Services Provided</h2>
        <p className="text-gray-700">
          QRdrly provides an online ordering system for your customers,
          including:
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li>QR code generation for menu access and orders.</li>
          <li>Integrated payment processing via third-party providers (e.g.,
          Stripe or Square).</li>
          <li>Delivery and pick-up management options.</li>
          <li>Real-time order tracking for your business and customers.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">4. Business Responsibilities</h2>
        <p className="text-gray-700">
          As a Business using QRdrly, you agree to:
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li>Provide accurate business information, including menu items,
          pricing, and availability.</li>
          <li>Ensure compliance with all applicable laws and regulations,
          including food safety and tax obligations.</li>
          <li>Promptly fulfill customer orders received through QRdrly.</li>
          <li>Pay any applicable fees as outlined in our pricing plan.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">5. Fees and Payments</h2>
        <p className="text-gray-700">
          QRdrly charges a service fee for providing the platform. Payment
          processing fees are handled by third-party providers. You are
          responsible for all applicable taxes. Details of fees and charges are
          outlined in our Pricing section.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">6. Termination</h2>
        <p className="text-gray-700">
          You may terminate your use of QRdrly Services at any time by
          providing written notice. QRdrly reserves the right to terminate your
          account for breach of these Terms or other reasons, including
          fraudulent activity or failure to pay fees.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">7. Limitation of Liability</h2>
        <p className="text-gray-700">
          QRdrly is not responsible for any direct or indirect damages arising
          from your use of our Services. Payment disputes, delivery issues, and
          other customer-related matters are solely your responsibility.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">8. Privacy</h2>
        <p className="text-gray-700">
          Your data and your customers' data will be handled in accordance with
          our Privacy Policy. By using QRdrly, you consent to the collection and
          processing of data as outlined in our policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">9. Changes to Terms</h2>
        <p className="text-gray-700">
          QRdrly reserves the right to modify these Terms at any time. Changes
          will be communicated via email or through the platform. Continued use
          of the Services constitutes your acceptance of the revised Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">10. Governing Law</h2>
        <p className="text-gray-700">
          These Terms are governed by the laws of Australia. Any
          disputes will be resolved in the courts of Victoria.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions or concerns about these Terms, please
          contact us at:
        </p>
        <p className="text-gray-700">
          Email: support@qrdrly.com
        </p>
        <p className="text-gray-700">
          Phone: [Your Support Phone Number]
        </p>
      </section>

      <div className="text-center mt-8">
        <a
          href="/signup"
          className="text-primary hover:underline text-lg font-medium"
        >
          Go Back to Sign Up
        </a>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUpWithEmail, signInWithGoogle, db } from "../../firebaseConfig"; // Replace with your Firebase functions
import googleIcon from "../../assets/google-logo.png";
import logo from "../../assets/logo192.png";
import { doc, setDoc } from "firebase/firestore";
import { createStripeCustomer } from "../../stripeService"; // Import your Stripe service

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false); // New state for terms checkbox
  const [businessName, setBusinessName] = useState("");

  const navigate = useNavigate();

  // Validation
  const validateForm = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      isValid = false;
    }
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      isValid = false;
    }
    return isValid;
  };

  // Email Sign Up Handler
  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setError("");
    setLoading(true);
    try {
      const userCredential = await signUpWithEmail(email, password);
      const user = userCredential.user;

      // Create a Stripe customer
      const stripeCustomer = await createStripeCustomer(user.uid, email);

      // Log the data to be saved
      console.log("Saving user data to Firestore:", {
        email: user.email,
        businessName: businessName,
        stripeCustomerId: stripeCustomer.id,
        subscriptionStatus: false, // Initialize subscriptionStatus as false
        createdAt: new Date(),
      });

      // Save user data to Firestore
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email,
        businessName: businessName,
        stripeCustomerId: stripeCustomer.id, // Store the Stripe customer ID
        subscriptionStatus: false, // Initialize subscriptionStatus as false
        createdAt: new Date(),
      });

      navigate("/dashboard"); // Redirect to the dashboard
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError(
          "This email is already in use. Please log in or use another email."
        );
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Google Sign Up Handler
  const handleGoogleSignUp = async () => {
    setError("");
    setLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential.user;

      // Create a Stripe customer
      const stripeCustomer = await createStripeCustomer(user.uid, user.email);

      // Log the data to be saved
      console.log("Saving user data to Firestore:", {
        email: user.email,
        businessName: businessName,
        stripeCustomerId: stripeCustomer.id,
        subscriptionStatus: false, // Initialize subscriptionStatus as false
        createdAt: new Date(),
      });

      // Save user data to Firestore
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email,
        businessName: businessName,
        stripeCustomerId: stripeCustomer.id, // Store the Stripe customer ID
        subscriptionStatus: false, // Initialize subscriptionStatus as false
        createdAt: new Date(),
      });

      navigate("/dashboard"); // Redirect to the dashboard
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container flex justify-center items-center h-screen bg-white">
      <div className="signup-form bg-white max-w-md w-full py-8 px-6">
        <div className="flex items-center gap-2 justify-center mb-6">
          <img className="w-10 h-10" src={logo} alt="logo" />
          <h1 className="text-2xl font-semibold">QRdrly | Sign Up</h1>
        </div>

        <form onSubmit={handleSignUp}>
          <div className="mb-4">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              className="email-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="email"
              placeholder="name@company.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
            />
            {emailError && (
              <p className="text-error text-sm mt-1">{emailError}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="sr-only">Business Name</label>
            <input
              id="businessName"
              className="border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Business Name"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              required
              aria-label="Business Name"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              className="password-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirm-password" className="sr-only">
              Confirm Password
            </label>
            <input
              id="confirm-password"
              className="password-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="password"
              placeholder="••••••••"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              aria-label="Confirm Password"
            />
            {passwordError && (
              <p className="text-error text-sm mt-1">{passwordError}</p>
            )}
          </div>
          {/* Terms and Conditions Checkbox */}
          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                className="mr-2"
              />
              <span className="text-sm">
                I agree to the{" "}
                <a href="/terms" className="text-primary hover:underline">
                  Terms and Conditions
                </a>
              </span>
            </label>
          </div>

          <button
            className="signup-button btn w-full bg-black text-white rounded-lg py-2 font-medium hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            type="submit"
            disabled={loading || !acceptTerms} // Disable if loading or terms not accepted
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </button>
        </form>

        <button
          className="google-signup-button flex items-center justify-center gap-2 w-full mt-4 px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-neutral hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          onClick={handleGoogleSignUp}
          disabled={loading || !acceptTerms} // Disable if loading or terms not accepted
        >
          <img className="w-5 h-5" src={googleIcon} alt="Google logo" />
          <span>{loading ? "Signing Up..." : "Sign up with Google"}</span>
        </button>

        {error && (
          <p className="text-error text-center text-sm mt-4">{error}</p>
        )}

        <p className="text-center mt-4">
          Already have an account?{" "}
          <a href="/login" className="text-primary hover:underline">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUp;

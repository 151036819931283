import React, { useEffect } from 'react';

const ExternalLink = ({ url }) => {
  useEffect(() => {
    window.open(url, '_blank');
  }, [url]);

  return null;
};

export default ExternalLink;
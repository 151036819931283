import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "../../firebaseConfig"; // Replace with your Firebase function
import logo from "../../assets/logo192.png"

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Handle Password Reset
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(email); // Firebase function to send reset email
      setSuccess(true);
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setError("No account found with this email.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container flex justify-center items-center h-screen bg-gray-100">
      <div className="forgot-password-form bg-white rounded-lg max-w-md w-full shadow-lg py-8 px-6">
        <div className="flex items-center gap-2 justify-center mb-6">
          <img className="w-10 h-10" src={logo} alt="logo" />
          <h1 className="text-2xl font-semibold">QRdrly | Reset Password</h1>
        </div>

        <form onSubmit={handlePasswordReset}>
          <div className="mb-4">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              className="email-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="email"
              placeholder="name@company.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
            />
          </div>

          {error && (
            <p className="text-red-500 text-center text-sm mb-4">{error}</p>
          )}
          {success && (
            <p className="text-green-500 text-center text-sm mb-4">
              A password reset email has been sent to {email}.
            </p>
          )}

          <button
            className="reset-password-button btn w-full bg-primary text-white rounded-lg py-2 font-medium hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Reset Email"}
          </button>
        </form>

        <p className="text-center mt-4">
          Remember your password?{" "}
          <a href="/login" className="text-primary hover:underline">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import PublicRoute from "./routes/PublicRoute";
import SignUp from "./components/authentication/SignUp";
import Login from "./components/authentication/Login";
import ForgotPassword from "./components/authentication/ForgotPassword";
import TermsAndConditions from "./components/TermsAndConditions";
import DashboardLayout from "./layouts/DashboardLayout";
import dashboardMenu from "./config/dashboardMenu"; // Import your menu configuration
import Loader from "./components/Loader"; // Optional: Add a loader for fallback
import useAuthState from "./hooks/useAuthState";



const App = () => {
  useAuthState();
  return (
    <AuthProvider>
      <div className="bg-base-200 min-h-screen">
        <Router>
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Public routes for unauthenticated users */}
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <PublicRoute>
                    <SignUp />
                  </PublicRoute>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <PublicRoute>
                    <ForgotPassword />
                  </PublicRoute>
                }
              />
              <Route
                path="/terms"
                element={
                  <PublicRoute>
                    <TermsAndConditions />
                  </PublicRoute>
                }
              />

              {/* Private route for authenticated users */}
              <Route
                path="/dashboard"
                element={
                  <AuthenticatedRoute>
                    <DashboardLayout />
                  </AuthenticatedRoute>
                }
              >
                {/* Nested dashboard routes */}
                {dashboardMenu.map((item) =>
                  item.isIndex ? (
                    <Route
                      key={item.name}
                      index // Default child route
                      element={<item.component />}
                    />
                  ) : (
                    <Route
                      key={item.name}
                      path={item.path} // Relative path
                      element={<item.component />}
                    />
                  )
                )}
              </Route>
               

              {/* Redirect root to login */}
              <Route path="/" element={<Navigate to="/login" replace />} />
            </Routes>
          </Suspense>
        </Router>
      </div>
    </AuthProvider>
  );
};

export default App;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import dashboardMenu from "../../config/dashboardMenu";
import logo from "../../assets/logo192.png";
import SubscriptionStatus from "../SubscriptionStatus";
import { IoChevronForwardCircle, IoClose, IoLogOutOutline } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import { signOut } from "../../firebaseConfig";
import { auth } from "../../firebaseConfig";

const SideNav = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleMobileSidebar = () => {
    setIsMobileOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  const handleHelpClick = (e) => {
    e.preventDefault();
    window.open("https://enchanted-transport-6ba.notion.site/17f7288be51e806b9238eb5ed3830713?pvs=105", "_blank");
  };

  return (
    <>
      {/* Mobile Overlay */}
      {isMobileOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMobileSidebar}
        ></div>
      )}

      <div
        className={`fixed z-50 bg-white text-black ${
          isMobileOpen
            ? "left-0"
            : "-left-64"
        } ${isCollapsed ? "w-16" : "w-64"} md:relative md:left-0 md:w-64 h-screen p-4 border-base-300  border-r-2 flex flex-col transition-all duration-300`}
      >
        {/* Logo Section */}
        <div className="logo-name-container flex justify-between items-center mb-5">
          <div className="flex items-center">
            <img
              className={`w-9 h-9 ${isCollapsed ? "hidden" : "pr-2"}`}
              src={logo}
              alt="logo"
            />
            {!isCollapsed && <h2 className="text-xl font-semibold">QRdrly</h2>}
          </div>
          {/* Close Button for Mobile */}
          <button
            className="md:hidden text-black"
            onClick={toggleMobileSidebar}
          >
            <IoClose size={24} />
          </button>
        </div>

        {/* Navigation Menu */}
        <nav className="flex-1">
          {dashboardMenu.map((item) => (
            <NavLink
              key={item.name}
              to={`/dashboard${item.path ? `/${item.path}` : ""}`}
              className={({ isActive }) =>
                `block py-2 px-4 rounded-lg ${
                  isActive
                    ? "bg-primary text-white"
                    : "hover:bg-primary hover:bg-opacity-20 text-black"
                }`
              }
              end={item.path === ""} // Only apply `end` for the root route
              onClick={item.name === "Help" ? handleHelpClick : undefined} // Handle Help click separately
            >
              <div
                className={`flex ${
                  isCollapsed ? "justify-center" : "justify-start"
                } items-center`}
              >
                <span className="icons text-lg">{item.icon}</span> {/* Render the icon */}
                {!isCollapsed && (
                  <span className="nav-items text-lg pl-2">{item.name}</span>
                )}
              </div>
            </NavLink>
          ))}
        </nav>

        {/* Subscription Status */}
        {!isCollapsed && <SubscriptionStatus />}

        {/* Logout Button */}
        <button
          className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-hover transition mt-4"
          onClick={handleLogout}
        >
          <IoLogOutOutline size={20} className="inline-block mr-2" />
          Logout
        </button>
      </div>

      {/* Mobile Toggle Button */}
      <button
        className="md:hidden fixed bottom-4 right-4 bg-black text-white p-3 rounded-full shadow-md z-50"
        onClick={toggleMobileSidebar}
      >
        {isMobileOpen ? <IoClose size={24} /> : <IoMdMenu size={24} />}
      </button>
    </>
  );
};

export default SideNav;

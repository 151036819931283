import { useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";

const useAuthState = () => {
  useEffect(() => {
    const handleAuthStateChanged = async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);

        try {
          const userSnap = await getDoc(userRef);
          if (!userSnap.exists()) {
            // Create the user document in Firestore if it doesn't exist
            await setDoc(
              userRef,
              {
                email: user.email,
                wizardState: {
                  currentStep: 0,
                  isComplete: false,
                },
                createdAt: new Date(),
              },
              { merge: true } // Prevent overwriting existing data
            );
          } else {
            // Update the user document without overwriting the wizardState
            await setDoc(
              userRef,
              {
                email: user.email,
                createdAt: new Date(),
              },
              { merge: true }
            );
          }
        } catch (error) {
          console.error("Error creating/updating user in Firestore:", error);
        }
      }
    };

    // Subscribe to auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        handleAuthStateChanged(user);
      }
    });

    // Cleanup subscription on component unmount
    return unsubscribe;
  }, []);
};

export default useAuthState;
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail as firebaseSendPasswordResetEmail} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrHwSUBDuXpxVfHoeKdln21EOOgCn-Z3E",
  authDomain: "qrdrly-prod.firebaseapp.com",
  projectId: "qrdrly-prod",
  storageBucket: "qrdrly-prod.firebasestorage.app",
  messagingSenderId: "63500019853",
  appId: "1:63500019853:web:8cb7d9de3e6299d1355ac4",
  measurementId: "G-VP0Z1M9ENR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

// Google Sign-In Function
const signInWithGoogle = () => {
  return signInWithPopup(auth, googleProvider);
};


// Sign-Up Function
const signUpWithEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// Login Function
const loginWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

//Reset Password
const sendPasswordResetEmail = async (email) => {
  const auth = getAuth();
  return firebaseSendPasswordResetEmail(auth, email);
};



export { auth, googleProvider, signInWithPopup, signOut, signUpWithEmail, loginWithEmail, signInWithGoogle, sendPasswordResetEmail, db };

// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebaseConfig"; // Adjust the path based on where your firebase.js file is located
import { onAuthStateChanged } from "firebase/auth";

// Create the AuthContext which is used as a global state for authentication purposes
export const AuthContext = createContext();

// AuthProvider component wraps the entire app to provide the user and loading state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store the authenticated user's data here
  const [loading, setLoading] = useState(true); // Track loading state

  // Monitor the authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update user state when auth state changes
      setLoading(false); // Set loading to false once auth state is resolved
    });

    // Cleanup subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { useEffect, useState } from 'react'
import { auth, db } from '../firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'

function Profile() {
  const [businessName, setBusinessName] = useState('')

  useEffect(() => {
    const fetchBusinessName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setBusinessName(userDoc.data().businessName);
        } else {
          console.log('Business Name does not exist');
        }
      }
    };

    fetchBusinessName();
  }, []);
  return (
    <div className="flex items-center space-x-4">
  <span
    className="w-10 h-10 rounded-full bg-gradient-to-tr from-purple via-pink to-cta border-2 border-base-300 flex items-center justify-center text-white font-semibold text-lg"
  >
  </span>
  <p className="text-gray-800 font-medium">{businessName}</p>
</div>
  )
}

export default Profile
import React, { useState } from "react";
import StoreStatus from "./StoreStatus";


function SubscriptionStatus() {
  const [isSubscribed, setIsSubscribed] = useState(false);

  console.log("SubscriptionStatus rendered");
  console.log("isSubscribed:", isSubscribed);

  return (
    <div className="container">
      {isSubscribed ? (
        <div className="flex items-center">
          <StoreStatus />
        </div>
      ) : (
        <div className="flex flex-col">
          
          <p className="text-sm">Please select a plan to launch your store</p>
        </div>
      )}
    </div>
  );
}

export default SubscriptionStatus;
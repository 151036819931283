import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginWithEmail, signInWithGoogle, db } from "../../firebaseConfig"; // Adjust the path as needed
import googleIcon from "../../assets/google-logo.png";
import logo from "../../assets/logo192.png";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { createStripeCustomer } from "../../stripeService"; // Import your Stripe service

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Redirect if already logged in (adjust based on your auth context)
  useEffect(() => {
    const currentUser = null; // Replace with your auth logic (e.g., `useAuth().currentUser`)
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [navigate]);

  // Validation
  const validateForm = () => {
    let isValid = true;
    setEmailError("");
    setPasswordError("");

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      isValid = false;
    }
    return isValid;
  };

  // Email Login Handler
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setError("");
    setLoading(true);
    try {
      await loginWithEmail(email, password);
      navigate("/dashboard");
    } catch (err) {
      if (err.code === "auth/invalid-credential") {
        setError("Your username or password is incorrect. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Google Login Handler
  const handleGoogleLogin = async () => {
    setError("");
    setLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential.user;

      // Check if the user already exists in Firestore
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // Create a Stripe customer if the user does not exist
        const stripeCustomer = await createStripeCustomer(user.uid, user.email);

        // Save user data to Firestore
        await setDoc(userRef, {
          email: user.email,
          stripeCustomerId: stripeCustomer.id, // Store the Stripe customer ID
          subscriptionStatus: false, // Initialize subscriptionStatus as false
          createdAt: new Date(),
        });
      }

      navigate("/dashboard");
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container flex justify-center items-center h-screen bg-white">
      <div className="login-form bg-white max-w-md w-full py-8 px-6">
        <div className="flex items-center gap-2 justify-center mb-6">
          <img className="w-10 h-10" src={logo} alt="logo" />
          <h1 className="text-2xl font-semibold">QRdrly | Sign In</h1>
        </div>

        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              className="email-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="email"
              placeholder="name@company.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
            />
            {emailError && (
              <p className="text-error text-sm mt-1">{emailError}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              id="password"
              className="password-field border border-gray-300 rounded-lg text-gray-900 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
            />
            {passwordError && (
              <p className="text-error text-sm mt-1">{passwordError}</p>
            )}
          </div>

          <div className="flex justify-end mb-4">
            <a
              href="/forgot-password"
              className="text-sm text-primary hover:underline"
            >
              Forgot password?
            </a>
          </div>

          <button
            className="login-button btn w-full bg-black text-white rounded-lg py-2 font-medium hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            type="submit"
            disabled={loading}
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>
        </form>

        <button
          className="google-login-button flex items-center justify-center gap-2 w-full mt-4 px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-neutral hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          onClick={handleGoogleLogin}
          disabled={loading}
        >
          <img className="w-5 h-5" src={googleIcon} alt="Google logo" />
          <span>{loading ? "Signing In..." : "Continue with Google"}</span>
        </button>

        {error && (
          <p className="text-error text-center text-sm mt-4">{error}</p>
        )}

        <p className="text-center mt-4">
          Don't have an account?{" "}
          <a href="/signup" className="text-primary hover:underline">
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import { FaHome, FaStore, FaExternalLinkAlt } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import ExternalLink from "../components/navigation/ExternalLinks";
//The purpose of this page is to configure the routing for dashboard menu items
//To add a new page, add an item to the array and add your file to the pages/YourPage.js
const dashboardMenu = [
  {
    name: "Home",
    path: "",
    isIndex: true, // Mark this as the default route
    component: React.lazy(() => import("../pages/Home")),
    icon: <FaHome />,
  },
  {
    name: "Store",
    path: "store",
    component: React.lazy(() => import("../pages/Store")),
    icon: <FaStore />,
  },
  {
    name: "Payment",
    path: "payment",
    component: React.lazy(() => import("../pages/Payment")),
    icon: <MdPayment />,
  },
  {
    name: "Settings",
    path: "settings",
    component: React.lazy(() => import("../pages/Settings")),
    icon: <IoSettings />,
  },
  {
    name: "Help",
    path: "help",
    component: () => <ExternalLink url="https://enchanted-transport-6ba.notion.site/17f7288be51e806b9238eb5ed3830713?pvs=105" />,
    icon: <FaExternalLinkAlt />,
  },
];

export default dashboardMenu;
